<template>
  <div class="">
    <!-- 修改状态弹窗 -->
    <status-form v-if="updateFormVisible" ref="statusForm" @refreshDataList="init"></status-form>

    <a-card :bordered="false" :title="taskData.title">
      <div slot="extra">
        <a-button type="primary" @click="$router.back()">返回</a-button>
      </div>
      <a-descriptions>
        <a-descriptions-item label="提交人">{{taskData.masterName}}</a-descriptions-item>
        <a-descriptions-item label="所属项目">{{taskData.projectName}}</a-descriptions-item>
        <a-descriptions-item label="优先级">
          <span v-if="taskData.level == 1">最高</span>
          <span v-if="taskData.level == 2">较高</span>
          <span v-if="taskData.level == 3">普通</span>
          <span v-if="taskData.level == 4">较低</span>
          <span v-if="taskData.level == 5">最低</span>
        </a-descriptions-item>
        <a-descriptions-item label="计划开始日期">{{startTime}}</a-descriptions-item>
        <a-descriptions-item label="计划完成日期">{{endTime}}</a-descriptions-item>
        <a-descriptions-item label="状态">
          <span v-if="taskData.status == 0">待开始</span>
          <span v-if="taskData.status == 1">进行中</span>
          <span v-if="taskData.status == 2">已完成</span>
          <div style="display:inline-block">
          <a-tooltip placement="bottom" title="修改" style="margin-left:5px"  v-if="userinfo.userId==masterId">
            <a-icon type="edit" @click="updateStatusEvt()" />
          </a-tooltip>
          </div>
        </a-descriptions-item>

        <status />
      </a-descriptions>
    </a-card>
    <a-row :gutter="24">
      <a-col :xl="16" :lg="24" :md="24" :sm="24" :xs="24">
        <a-card :bordered="false" style="margin-top: 20px; height:908px;overflow-y:auto;">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="详情" force-render>
              <div class="antd-table-wrap" style="  height: 550px;overflow-y: auto;">
                <a-descriptions title="">
                  <a-descriptions-item label="创建时间">{{$moment(this.taskData.createTime).format("YYYY-MM-DD HH:mm:ss")}}</a-descriptions-item>
                  <a-descriptions-item label="更新时间">{{this.taskData.modifyTime ? $moment(this.taskData.modifyTime).format("YYYY-MM-DD HH:mm:ss") : "" }}</a-descriptions-item>
                </a-descriptions>
                <div>
                  <span style="display:inline-block;padding-bottom:10px">描述：</span>
                   <span v-html="taskData.content"></span>
                </div>
              </div>
            </a-tab-pane>
          </a-tabs>
        </a-card>
      </a-col>
      <a-col
        style="padding: 0 12px"
        :xl="8"
        :lg="24"
        :md="24"
        :sm="24"
        :xs="24"
      >
        <!-- 页面类型：0阶段 1评审点 2需求，3任务，4求助单-->
        <trends :pageType="2" :projectId="taskData.projectId" :taskId="this.$route.query.taskId" :taskType="1" />
      </a-col>
    </a-row>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import StatusForm from "@/components/Status";
import Trends from "@/components/Trends";
import moment from "moment";
import { detailTask } from "@/httpsAPI/requirement";

export default {
  name: "OrdersDetail",
  components: { Trends,  StatusForm },
  data() {
    return {
      updateFormVisible:false,
      loading: false,
      taskData: {},
      startTime: "",
      endTime: "",
      projectId: "",
      taskId: 0,
      type:3,
      statusId:'',
      status:"",
      masterId:''
    };
  },
  computed: {
     ...mapGetters(['userinfo']),
    title() {
      return this.$route.meta.title;
    },
  },
  mounted() {
    this.init();
  },
  created(){
    this.statusId = this.$route.query.taskId
    this.masterId = this.$route.query.masterId
  },
  methods: {
    // 修改状态
    updateStatusEvt() {
      this.updateFormVisible = true
      this.$nextTick(() => {
        this.$refs.statusForm.init(this.statusId,this.type,this.status)
      })
    },
    init() {
      const that = this;
      this.$nextTick(() => {
          detailTask({ id: this.$route.query.taskId }).then((res) => {
            that.taskData = res.data;
            that.taskId = that.taskData.id
            that.status = res.data.status
            that.startTime = that.taskData.startTime ? moment(that.taskData.startTime).format("YYYY年MM月DD日") : "";
            that.endTime = that.taskData.endTime ? moment(that.taskData.endTime).format("YYYY年MM月DD日") : "";

            console.log('************', that.taskData)
          });
      });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 16px;
}
.layout-content-view{
  overflow: hidden;
}
</style>
